import React from "react"
import classNames from "classnames"

import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"

import { getData } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function ReportsFeatures(props: Props) {
	const localizationContext = useLocalizationContext()

	const data = getData(localizationContext.locale);

	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				className={styles.featuresGallery}
				theme={"fuchsia-custom-backdrop"}
				features={data.features}
				direction={FEATURE_GALLERY_DIRECTION.REVERSE}
			/>
		</section>
	)
}
