import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { externalLinks } from '../../../pages-data/_V2/common/links'
import styles from './styles.module.scss'
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			pageName: 'приложение для руководителя',
			desktopTitle: 'Установи приложение Quick Resto Manager',
			subTitle: (
				<p className={styles.description}>
					Мобильное приложение для владельца ресторана, кафе или других точек
					общепита&nbsp;&mdash; следи за&nbsp;продажами, быстро реагируй
					на&nbsp;сомнительные операции по&nbsp;кассе, анализируй показатели.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/qrm-presentation-kz.png"
					alt="приложение для владельца ресторана"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayQRMRoistat,
					alt: 'Google Play',
				},
				appstore: {
					href: externalLinks.appstoreQRMRoistat,
					alt: 'App Store',
				},
			},
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: 'басшыға арналған қосымша',
			desktopTitle: 'Quick Resto Manager қосымшасын орнатыңыз',
			subTitle: (
				<p className={styles.description}>
					Мейрамхана, кафе немесе басқа тқоғамдық тамақтандыру нүктелеріне арналған мобильді қосымша -
					сатуды қадағалаңыз, касса бойынша күмәнді операцияларға тез жауап қайтарыңыз, көрсеткіштерді талдаңыз.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/qrm-presentation-kz.png"
					alt="приложение для владельца ресторана"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayQRMRoistat,
					alt: 'Google Play',
				},
				appstore: {
					href: externalLinks.appstoreQRMRoistat,
					alt: 'App Store',
				},
			},
		}
	}

	if (locale === 'ru-BY') {
		return {
			pageName: 'приложение для руководителя',
			desktopTitle: 'Скачай приложение Quick Resto Manager',
			subTitle: (
				<p className={styles.description}>
					Мобильное приложение для владельца ресторана, кафе или других точек
					общепита&nbsp;&mdash; следи за&nbsp;продажами, быстро реагируй
					на&nbsp;сомнительные операции по&nbsp;кассе, анализируй показатели.
				</p>
			),
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/qrm-presentation-by.png"
					alt="приложение для владельца ресторана"
					objectFit="contain"
					objectPosition="center"
					placeholder="blurred"
					quality={90}
				/>
			),
			storeBadges: {
				googleplay: {
					href: externalLinks.googlePlayQRMRoistat,
					alt: 'Google Play',
				},
				appstore: {
					href: externalLinks.appstoreQRMRoistat,
					alt: 'App Store',
				},
			},
		}
	}

	return {
		pageName: 'приложение для руководителя',
		desktopTitle: 'Мобильное приложение Quick Resto Manager',
		subTitle: (
			<p className={styles.description}>
				Мобильное приложение для владельца ресторана, кафе или других точек
				общепита&nbsp;&mdash; следи за&nbsp;продажами, быстро реагируй
				на&nbsp;сомнительные операции по&nbsp;кассе, анализируй показатели.
			</p>
		),
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/qrm-presentation.png"
				alt="приложение для владельца ресторана"
				objectFit="contain"
				objectPosition="center"
				placeholder="blurred"
				quality={90}
			/>
		),
		storeBadges: {
			googleplay: {
				href: externalLinks.googlePlayQRMRoistat,
				alt: 'Google Play',
			},
			appstore: {
				href: externalLinks.appstoreQRMRoistat,
				alt: 'App Store',
			},
		},
	}
}
