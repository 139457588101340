import React from "react"
import {Locales} from "../../../localization/types";
import {StaticImage} from "gatsby-plugin-image";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-1.png"
						alt="Регулярно о главном"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Регулярно о главном",
				description:
					"Отчёты и уведомления о работе бизнеса помогут тебе принимать эффективные решения.",
			},
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-2.png"
						alt="Финансы — наглядно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Финансы — наглядно",
				description:
					"Считай фудкост, анализируй прибыль и следи за всеми движениями денег.",
			},
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-3.png"
						alt="Готовое решение"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Готовое решение",
				description:
					"Никаких дополнительных расходов: приложение руководителя Quick Resto Manager доступно на всех тарифах.",
			},
		]
	}

	if (locale === 'kz-KZ') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-1.png"
						alt="Регулярно о главном"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Бастысы туралы жүйелі түрде",
				description:
					"Есептер және бизнестің жұмысы туралы хабарламалар басшыға тиімді шешімдерді қабылдауына көмектеседі.",
			},
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-2.png"
						alt="Финансы — наглядно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Қаржы - көрнекі түрде",
				description:
					"Фудкостты есептеңіз, пайданы талдаңыз және ақшаның барлық қоғалыстарын қадағалаңыз.",
			},
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-3.png"
						alt="Готовое решение"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Дайын шешім",
				description:
					"Ешқандай қосымша шығыстар: Quick Resto Manager басшы қосымшасы барлық тарифтерде қолжетімді.",
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-1.png"
						alt="Регулярно о главном"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Регулярно о главном",
				description:
					"Отчёты и уведомления о работе бизнеса помогут тебе принимать эффективные решения.",
			},
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-2.png"
						alt="Финансы — наглядно"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Финансы — наглядно",
				description:
					"Контролируй фудкост, система покажет в отчетах реальную прибыль.",
			},
			{
				img: (
					<StaticImage
						src="./assets/qrm-advantages-3.png"
						alt="Готовое решение"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				title: "Готовое решение",
				description:
					"Никаких дополнительных расходов: приложение руководителя Quick Resto Manager доступно на всех тарифах.",
			},
		]
	}

	return [
		{
			img: (
				<StaticImage
					src="./assets/qrm-advantages-1.png"
					alt="Регулярно о главном"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Регулярно о главном",
			description:
				"Отчёты и уведомления о работе бизнеса помогают руководителю принимать эффекивные решения.",
		},
		{
			img: (
				<StaticImage
					src="./assets/qrm-advantages-2.png"
					alt="Финансы — наглядно"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Финансы — наглядно",
			description:
				"Контролируй фудкост, система покажет в отчетах реальную прибыль.",
		},
		{
			img: (
				<StaticImage
					src="./assets/qrm-advantages-3.png"
					alt="Готовое решение"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			title: "Готовое решение",
			description:
				"Никаких дополнительных расходов: приложение руководителя Quick Resto Manager доступно на всех тарифах.",
		},
	]
}

export default [
	{
		title: "Регулярно о главном",
		description:
			"Отчёты и уведомления о работе бизнеса помогают руководителю принимать эффекивные решения.",
	},
	{
		title: "Финансы — наглядно",
		description:
			"Контролируй фудкост, система покажет в отчетах реальную прибыль.",
	},
	{
		title: "Готовое решение",
		description:
			"Никаких дополнительных расходов: приложение руководителя Quick Resto Manager доступно на всех тарифах.",
	},
]
