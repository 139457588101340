import React from 'react'

import classNames from 'classnames'
import { requestForQRMProTariff } from '../apiRequests/callToAction'

import DefaultLayout from '../layout/default'

import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import ProductPresentation from '../pagesSections/qrm/ProductPresentation'
import DevicesImg from '../pagesSections/qrm/ProductPresentation/assets/qrm-presentation.png'
import DevicesImgKz from '../pagesSections/qrm/ProductPresentation/assets/qrm-presentation-kz.png'
import DevicesImgBy from '../pagesSections/qrm/ProductPresentation/assets/qrm-presentation-by.png'

import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import { getData as getFaqData } from '../pages-data/_V2/qrm/faq'
import { getData as getAdvantagesData } from '../pages-data/_V2/qrm/advantages'
import { getData as getFeaturesData } from '../pages-data/_V2/qrm/features'
import { externalLinks } from '../pages-data/_V2/common/links'

import BusinessTypes from '../components/_V2/BusinessTypes'
import Advantages from '../components/_V2/Advantages'
import Faq from '../components/_V2/Faq'

import styles from './styles/_V2/qrm.module.scss'
import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import CtaForm from '../pagesSections/qrm/CtaForm'
import SimpleFeatures from '../components/_V2/SimpleFeatures'
import ReportsFeatures from '../pagesSections/qrm/ReportsFeatures'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function QRMPage() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Мобильное приложение для владельцев ресторанного бизнеса',
    'ru-KZ': 'Мобильное приложение для владельцев ресторанного бизнеса | Quick Resto Казахстан',
    'kz-KZ': 'Мобильное приложение для владельцев ресторанного бизнеса | Quick Resto Казахстан',
    'ru-BY': 'Мобильное приложение для владельцев ресторанного бизнеса | Quick Resto Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}qrm/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}qrm/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}qrm/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}qrm/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Аналитика по бизнесу в мобильном приложении для владельцев и администраторов кафе, ресторанов, баров → Следите за продажами и анализируйте показатели из любой точки',
    'ru-KZ': 'Аналитика по бизнесу в мобильном приложении для владельцев и администраторов кафе, ресторанов, баров → Следи за продажами и анализируй показатели из любой точки. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Аналитика по бизнесу в мобильном приложении для владельцев и администраторов кафе, ресторанов, баров → Следи за продажами и анализируй показатели из любой точки. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Аналитика по бизнесу в мобильном приложении для владельцев и администраторов кафе, ресторанов, баров → Следи за продажами и анализируй показатели из любой точки. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${DevicesImg}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${DevicesImgKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${DevicesImgKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${DevicesImgBy}`
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    // {
    //   name: 'keywords',
    //   content: 'программа, мобильное приложение, ресторанный бизнес, аналитика, общепит, автоматизация, кафе, ресторан',
    // },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale],
    },
  ]

  const featuresData = getFeaturesData(localizationContext.locale)
  const faqData = getFaqData(localizationContext.locale)
  const advantagesData = getAdvantagesData(localizationContext.locale)

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={currentCanonicalLink}
      schemaType="Product"
      schemaName={currentTitle}
      schemaDescription={metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        className={classNames(styles.qrm__advantages, pageStyles.pageSection)}
        items={advantagesData}
        hideImages={false}
      />

      <ReportsFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        className={pageStyles.pageSection}
        data={featuresData.filter((feature, index) => index < 2)}
      />

      <CtaForm
        className={pageStyles.pageSection}
        request={requestForQRMProTariff}
      />

      <SimpleFeatures
        className={pageStyles.pageSection}
        data={featuresData.filter((feature, index) => index >= 2)}
      />

      <Faq data={faqData} className={pageStyles.pageSection} withShadow />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
