import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import styles from './styles.module.scss'

import { pagesLinks } from '../common/links'
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
    if (locale === 'ru-KZ') {
        return [
            {
                category: 'безопасность',
                title: 'Полная информация по каждому чеку',
                description: (
                    <>
                        <p>
                            Официант отменил пречек или возникла спорная ситуация
                            с&nbsp;оплатой&nbsp;&mdash; всё это легко проверить.
                        </p>
                        <p>
                            Мобильное приложение для владельца ресторана содержит все детали чека
                            и&nbsp;состав заказа: кто обслуживал, какой стол, количество гостей.
                        </p>
                    </>
                ),

                img: (
                    <StaticImage
                        className={styles.image1}
                        src="./assets/qrm-feature-1-kz.png"
                        alt="отчет по чека в ресторане"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                reverse: true,
                className: styles.feature1,
                theme: 'fuchsia',
            },
            {
                category: 'контроль',
                title: 'Моментальные\nпуш-уведомления',
                tabletTitle: 'Моментальные пуш-уведомления',
                description: (
                    <p>
                        Узнай о&nbsp;закрытии смены, сомнительных операциях по&nbsp;кассе или
                        отзывах, оставленных посетителями в&nbsp;приложении для гостей.
                    </p>
                ),

                mediaContent: (
                    <div className={styles.image2}>
                        <StaticImage
                            className={styles.phone}
                            src="./assets/qrm-feature-2-1.png"
                            alt="пуш-уведомления для руководителя"
                            objectFit="contain"
                            placeholder="blurred"
                            quality={90}
                        />

                        <StaticImage
                            className={styles.notifies}
                            src="./assets/qrm-feature-2-2-kz.png"
                            alt="пуш-уведомления для руководителя"
                            objectFit="contain"
                            placeholder="blurred"
                            quality={90}
                        />
                    </div>
                ),

                className: styles.feature2,
                theme: 'primary',
            },
            {
                category: 'решения',
                title: 'Получай подробную аналитику в приложении',
                description: (
                    <div className={styles.feature3Description}>
                        <p>
                            В&nbsp;какой день месяца посещаемость обычно больше? Как&nbsp;меняется
                            выручка в&nbsp;зависимости от&nbsp;дня недели?
                        </p>
                        <p>
                            Открой график и&nbsp;в&nbsp;деталях сравни продажи за&nbsp;разные
                            недели, месяцы, годы.
                        </p>
                    </div>
                ),

                img: (
                    <StaticImage
                        className={styles.image3}
                        src="./assets/qrm-feature-3-kz.png"
                        alt="аналитика для владельца ресторана"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                reverse: true,
                className: styles.feature3,
                theme: 'cyan',
            },
            {
                category: 'отчеты и аналитика',
                title: 'Принимай правильные решения',
                tabletTitle: 'Принимай правильные решения',
                mobileTitle: 'Принимай правильные решения',
                description: (
                    <p>
                        Следи за&nbsp;показателями заведения. Найди баланс вкуса и&nbsp;прибыли.
                        Скорректируй меню в&nbsp;пользу маржинальных и&nbsp;популярных позиций.
                    </p>
                ),

                img: (
                    <StaticImage
                        className={styles.image4}
                        src="./assets/qrm-feature-4-kz.png"
                        alt="отчет по блюдам"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                className: styles.feature4,
                theme: 'green',

                actionText: 'Подробнее',
                actionLink: pagesLinks.reports.href,
            },
        ]

    }

    if (locale === 'kz-KZ') {
        return [
            {
                category: 'қауіпсіздік',
                title: 'Әр чек бойынша толық ақпарат',
                description: (
                    <>
                        <p>
                            Даяшы пречекті болдырмады немесе төлемге байланысты даулы жағдай туындады - бұның бәрін тексеру оңай.
                        </p>
                        <p>
                            Мейрамхана иесіне арналған мобильді қосымшаның құрамында чектің барлық деректері және
                            тапсырыстың құрамы бар: кім қызмет көрсетті, қай үстелге қызмет көрсетті, қонақтар саны.
                        </p>
                    </>
                ),

                img: (
                    <StaticImage
                        className={styles.image1}
                        src="./assets/qrm-feature-1-kz.png"
                        alt="отчет по чека в ресторане"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                reverse: true,
                className: styles.feature1,
                theme: 'fuchsia',
            },
            {
                category: 'бақылау',
                title: 'Лездік пуш-\nхабарламалар',
                tabletTitle: 'Лездік пуш-хабарламалар',
                description: (
                    <p>
                        Ауысымның жабылуы туралы, касса туралы күмәнді операциялар немесе келушілер қонақтарға
                        арналған қосымшада қалдырған пікірлер туралы біліңіз.
                    </p>
                ),

                mediaContent: (
                    <div className={styles.image2}>
                        <StaticImage
                            className={styles.phone}
                            src="./assets/qrm-feature-2-1.png"
                            alt="пуш-уведомления для руководителя"
                            objectFit="contain"
                            placeholder="blurred"
                            quality={90}
                        />

                        <StaticImage
                            className={styles.notifies}
                            src="./assets/qrm-feature-2-2-kz.png"
                            alt="пуш-уведомления для руководителя"
                            objectFit="contain"
                            placeholder="blurred"
                            quality={90}
                        />
                    </div>
                ),

                className: styles.feature2,
                theme: 'primary',
            },
            {
                category: 'шешімдер',
                title: 'Қосымшада толық аналитиканы алыңыз',
                description: (
                    <div className={styles.feature3Description}>
                        <p>
                            Келушілер саны әдетте айдың қай күнінде көбірек болады? Түсім аптаның күніне байланысты қалай өзгереді?
                        </p>
                        <p>
                            Графикті ашыңыз және әртүрлі апталар, айлар, жылдар ішіндегі сатудаы толық салыстырыңыз.
                        </p>
                    </div>
                ),

                img: (
                    <StaticImage
                        className={styles.image3}
                        src="./assets/qrm-feature-3-kz.png"
                        alt="аналитика для владельца ресторана"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                reverse: true,
                className: styles.feature3,
                theme: 'cyan',
            },
            {
                category: 'есептер мен аналитика',
                title: 'Дұрыс шешімдерді қабылдаңыз',
                description: (
                    <p>
                        Мекеменің көрсеткіштерін қадағалаңыз. Дәм мен пайда балансын табыңыз.
                        Мәзірді маржиналдық және танымал позициялардың пайдасына түзетіңіз.
                    </p>
                ),

                img: (
                    <StaticImage
                        className={styles.image4}
                        src="./assets/qrm-feature-4-kz.png"
                        alt="отчет по блюдам"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                className: styles.feature4,
                theme: 'green',

                actionText: 'толығырақ',
                actionLink: pagesLinks.reports.href,
            },
        ]

    }

    if (locale === 'ru-BY') {
        return [
            {
                category: 'безопасность',
                title: 'Полная информация по каждому чеку',
                description: (
                    <>
                        <p>
                            Официант отменил пречек или возникла спорная ситуация
                            с&nbsp;оплатой&nbsp;&mdash; всё это легко проверить.
                        </p>
                        <p>
                            Мобильное приложение для владельца ресторана содержит все детали чека
                            и&nbsp;состав заказа: кто обслуживал, какой стол, количество гостей.
                        </p>
                    </>
                ),

                img: (
                    <StaticImage
                        className={styles.image1}
                        src="./assets/qrm-feature-1-by.png"
                        alt="отчет по чека в ресторане"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                reverse: true,
                className: styles.feature1,
                theme: 'fuchsia',
            },
            {
                category: 'контроль',
                title: 'Моментальные\nпуш-уведомления',
                tabletTitle: 'Моментальные пуш-уведомления',
                description: (
                    <p>
                        Узнай о&nbsp;закрытии смены, сомнительных операциях по&nbsp;кассе или
                        отзывах, оставленных посетителями в&nbsp;приложении для гостей.
                    </p>
                ),

                mediaContent: (
                    <div className={styles.image2}>
                        <StaticImage
                            className={styles.phone}
                            src="./assets/qrm-feature-2-1.png"
                            alt="пуш-уведомления для руководителя"
                            objectFit="contain"
                            placeholder="blurred"
                            quality={90}
                        />

                        <StaticImage
                            className={styles.notifies}
                            src="./assets/qrm-feature-2-2-by.png"
                            alt="пуш-уведомления для руководителя"
                            objectFit="contain"
                            placeholder="blurred"
                            quality={90}
                        />
                    </div>
                ),

                className: styles.feature2,
                theme: 'primary',
            },
            {
                category: 'решения',
                title: 'Приложение даст подробную аналитику',
                description: (
                    <div className={styles.feature3Description}>
                        <p>
                            В&nbsp;какой день месяца посещаемость обычно больше? Как&nbsp;меняется
                            выручка в&nbsp;зависимости от&nbsp;дня недели?
                        </p>
                        <p>
                            Открой график и&nbsp;в&nbsp;деталях сравни продажи за&nbsp;разные
                            недели, месяцы, годы.
                        </p>
                    </div>
                ),

                img: (
                    <StaticImage
                        className={styles.image3}
                        src="./assets/qrm-feature-3-by.png"
                        alt="аналитика для владельца ресторана"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                reverse: true,
                className: styles.feature3,
                theme: 'cyan',
            },
            {
                category: 'отчеты и аналитика',
                title: 'Верные решения на основе данных',
                tabletTitle: 'Верные решения на основе данных',
                mobileTitle: 'Верные решения на основе данных',
                description: (
                    <p>
                        Следи за&nbsp;показателями заведения. Найди баланс вкуса и&nbsp;прибыли.
                        Скорректируй меню в&nbsp;пользу маржинальных и&nbsp;популярных позиций.
                    </p>
                ),

                img: (
                    <StaticImage
                        className={styles.image4}
                        src="./assets/qrm-feature-4-by.png"
                        alt="отчет по блюдам"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                className: styles.feature4,
                theme: 'green',

                actionText: 'Подробнее',
                actionLink: pagesLinks.reports.href,
            },
        ]
    }

    return [
        {
            category: 'безопасность',
            title: 'Полная информация по каждому чеку',
            description: (
                <>
                    <p>
                        Официант отменил пречек или возникла спорная ситуация
                        с&nbsp;оплатой&nbsp;&mdash; всё это легко проверить.
                    </p>
                    <p>
                        Мобильное приложение для владельца ресторана содержит все детали чека
                        и&nbsp;состав заказа: кто обслуживал, какой стол, количество гостей.
                    </p>
                </>
            ),

            img: (
                <StaticImage
                    className={styles.image1}
                    src="./assets/qrm-feature-1.png"
                    alt="отчет по чека в ресторане"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={90}
                />
            ),

            reverse: true,
            className: styles.feature1,
            theme: 'fuchsia',
        },
        {
            category: 'контроль',
            title: 'Моментальные\nпуш-уведомления',
            tabletTitle: 'Моментальные пуш-уведомления',
            description: (
                <p>
                    Узнай о&nbsp;закрытии смены, сомнительных операциях по&nbsp;кассе или
                    отзывах, оставленных посетителями в&nbsp;приложении для гостей.
                </p>
            ),

            mediaContent: (
                <div className={styles.image2}>
                    <StaticImage
                        className={styles.phone}
                        src="./assets/qrm-feature-2-1.png"
                        alt="пуш-уведомления для руководителя"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />

                    <StaticImage
                        className={styles.notifies}
                        src="./assets/qrm-feature-2-2.png"
                        alt="пуш-уведомления для руководителя"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={90}
                    />
                </div>
            ),

            className: styles.feature2,
            theme: 'primary',
        },
        {
            category: 'решения',
            title: 'Аналитика в приложении для владельца ресторана',
            description: (
                <div className={styles.feature3Description}>
                    <p>
                        В&nbsp;какой день месяца посещаемость обычно больше? Как&nbsp;меняется
                        выручка в&nbsp;зависимости от&nbsp;дня недели?
                    </p>
                    <p>
                        Открой график и&nbsp;в&nbsp;деталях сравни продажи за&nbsp;разные
                        недели, месяцы, годы.
                    </p>
                </div>
            ),

            img: (
                <StaticImage
                    className={styles.image3}
                    src="./assets/qrm-feature-3.png"
                    alt="аналитика для владельца ресторана"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={90}
                />
            ),

            reverse: true,
            className: styles.feature3,
            theme: 'cyan',
        },
        {
            category: 'отчеты и аналитика',
            title: 'Принимай правильные решения',
            tabletTitle: 'Принимай правильные решения',
            mobileTitle: 'Принимай правильные решения',
            description: (
                <p>
                    Следи за&nbsp;показателями заведения. Найди баланс вкуса и&nbsp;прибыли.
                    Скорректируй меню в&nbsp;пользу маржинальных и&nbsp;популярных позиций.
                </p>
            ),

            img: (
                <StaticImage
                    className={styles.image4}
                    src="./assets/qrm-feature-4.png"
                    alt="отчет по блюдам"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={90}
                />
            ),

            className: styles.feature4,
            theme: 'green',

            actionText: 'Подробнее',
            actionLink: pagesLinks.reports.href,
        },
    ]
}
