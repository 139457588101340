import * as React from "react"

const FryPhone = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="150"
		height="140"
		viewBox="0 0 150 140"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M82.1434 44.4398C82.2663 43.3877 81.818 42.4708 81.1422 42.3919C80.4664 42.3129 79.8189 43.1019 79.6961 44.154C79.5732 45.2062 80.0215 46.1231 80.6973 46.202C81.3731 46.2809 82.0206 45.4919 82.1434 44.4398Z"
			fill="#2A2D33"
		/>
		<path
			d="M71.7641 44.3171C71.8932 43.2112 71.4251 42.2478 70.7186 42.1653C70.0121 42.0828 69.3346 42.9125 69.2055 44.0184C69.0764 45.1243 69.5444 46.0876 70.251 46.1701C70.9575 46.2526 71.635 45.423 71.7641 44.3171Z"
			fill="#2A2D33"
		/>
		<path
			d="M32.4521 29.554C18.5501 36.778 8.05016 49.476 3.61217 64.596C1.68018 71.106 0.896181 78.106 1.44218 85.274C2.24018 95.984 5.92217 105.826 11.6761 114.002C22.9741 130.284 42.3641 140.252 63.574 138.67C66.71 138.432 69.804 137.956 72.786 137.214C74.732 136.738 76.636 136.192 78.512 135.52C83.6079 133.728 88.3819 131.222 92.6799 128.128C94.7939 126.63 96.7679 124.992 98.6439 123.242H98.6719L98.8819 123.032"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M89.6143 35.616C94.5702 38.542 99.0922 42.238 102.984 46.536C109.718 53.928 114.618 63.21 116.788 73.738C117.11 75.32 117.362 76.902 117.558 78.484"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M50.358 58.814C47.936 57.918 45.92 55.776 45.472 53.242C45.024 50.708 46.312 47.866 48.664 46.746C51.016 45.668 54.208 46.634 55.314 48.958C57.974 44.604 57.484 38.584 54.236 34.664C55.216 35.462 56.924 35.266 57.848 34.272C58.856 33.166 58.898 31.402 58.38 29.974C57.862 28.546 56.826 27.384 55.846 26.25C57.512 28.182 63.4199 28.938 65.8699 29.414C69.4259 30.072 73.0659 30.898 76.7339 30.814C80.9759 30.73 83.6779 29.26 83.6779 29.26C86.1559 32.27 85.6519 37.968 85.2599 40.572C84.7839 43.652 83.8319 46.732 83.1179 49.756"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M72.9961 73.5154C76.0621 73.1654 78.6101 71.6534 80.0101 68.1954C81.2141 65.2414 81.7181 61.2234 81.9841 57.9614"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M78.1338 57.0639C79.5898 58.1839 81.6898 58.2399 83.2298 57.3439C83.4818 57.2039 83.6918 57.0219 83.9158 56.8259C85.4418 55.3699 85.6938 52.7799 84.4898 51.0859C84.4058 50.9739 84.3218 50.8619 84.2098 50.7639C83.0898 49.3919 81.2278 48.7619 79.4778 48.2439"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M83.72 29.3581C85.778 28.4761 87.122 26.3761 87.556 24.1921C87.99 22.0081 87.626 19.7261 87.052 17.5701C85.386 11.3261 81.858 5.58609 77.042 1.28809C77.196 4.18609 75.642 7.12609 73.164 8.63809C72.198 5.29209 68.992 2.73009 65.506 2.52009C67.102 4.43809 66.668 7.33609 65.59 9.57609C64.932 10.9481 64.008 12.2781 62.65 12.9641C60.606 14.0001 58.156 13.3561 55.874 13.3421C50.5821 13.3281 45.5421 17.2901 44.3241 22.4281C44.3521 21.4481 44.3661 20.3981 43.8341 19.5581C43.3021 18.7181 42.0281 18.2981 41.3141 18.9841C40.9501 19.3341 40.8381 19.8801 40.7821 20.3841C40.6421 21.8401 40.9361 23.3381 41.6081 24.6401C40.4321 23.1281 38.5561 21.5881 36.8201 22.4001C35.3081 23.1001 35.0421 25.2701 35.8401 26.7261C36.6381 28.1821 38.1501 29.0781 39.5781 29.9041C36.6941 37.2121 39.2001 46.2701 45.4301 51.0581"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M50.3578 58.814C50.3298 62.566 49.6438 66.696 48.2578 70.196"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M74.0182 73.3599C75.1802 74.2559 76.5662 75.3899 77.6162 76.1739C74.3962 82.4599 66.7943 94.6959 66.7943 94.6959C66.2203 88.7039 64.4143 82.8239 61.5723 77.4899"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M49.4624 65.1711C48.1604 65.1291 46.8584 65.2131 45.5844 65.4371C44.6884 65.5911 43.6524 65.9411 43.4424 66.8231C43.2324 67.7331 44.0584 68.5311 44.8004 69.0911C49.7984 72.8011 55.6083 75.4891 61.4883 77.4911"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M72.4639 85.5543C74.4239 86.1563 76.2439 87.2063 77.7419 88.5923C78.1199 84.5463 77.8539 80.2343 77.6159 76.1743"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M61.4884 78.1619C60.4944 82.2919 60.2424 86.6038 60.7464 90.8318C56.9944 89.6418 53.2844 88.2558 49.8404 86.3518C46.3964 84.4478 43.2184 81.9838 40.7824 78.9038C40.2504 78.2318 39.7464 77.5178 39.5364 76.6918C39.3544 75.9638 39.3964 75.1938 39.5084 74.4518C39.9424 71.3298 41.5384 68.8238 43.6104 66.4438"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.03027 90.3011C10.9482 78.8771 25.3262 71.7511 39.8582 72.1291"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M129.766 100.59C127.82 104.664 116.186 116.116 110.502 121.506C107.52 124.334 103.152 125.09 99.3857 123.438L82.1377 111.692C82.2077 119.07 80.4577 128.464 78.5117 135.548"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M78.2041 78.1763C86.1141 78.8903 92.6241 88.4943 102.956 95.3963C103.768 95.9283 104.832 95.9003 105.602 95.3123L120.582 82.6983"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.5803 116.577C13.3983 110.221 14.2663 103.837 16.1703 97.7332"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M120.568 82.6987C120.568 85.8627 123.522 92.0367 126.238 96.2087"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M120.567 82.6991C122.149 78.5831 127.637 73.4731 131.081 73.4731"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M126.322 95.7182C126.168 95.7182 126 95.6622 125.874 95.6062"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M140.28 95.8854C139.888 98.4194 139.776 99.8614 137.424 100.015L128.408 100.575C126.336 100.799 125.664 98.4054 126.14 96.6554L131.894 69.6634C132.202 68.4454 132.342 66.0374 135.478 65.8274L143.892 65.3794C145.67 65.3794 146.258 66.5974 146.006 67.7314L144.97 73.0374"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M133.392 78.3001L134.904 71.0761C135.086 70.3621 135.17 68.9481 137.004 68.8221L140.336 68.6401"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M139.397 78.4986C137.829 78.8066 136.373 80.1646 136.471 81.6346C136.527 82.5166 137.311 83.2726 138.235 83.5946C139.159 83.9166 140.195 83.8606 141.161 83.6646C140.223 83.9586 139.271 84.2666 138.501 84.8266C137.731 85.3866 136.751 86.1986 136.723 87.4306C136.695 88.6626 137.661 90.5526 140.629 89.6846C139.523 90.5526 137.465 91.1686 137.157 92.4566C136.849 93.7446 137.129 94.6266 138.431 95.2566C139.425 95.7326 140.671 95.6206 141.679 95.1586C142.687 94.6966 144.731 93.1146 145.445 92.3446C147.643 90.0346 146.257 87.7666 148.077 83.3286C149.085 80.8786 148.847 74.4666 145.809 72.8146C144.521 72.1146 144.647 75.9086 143.835 76.9866C142.771 78.4006 141.497 78.0926 139.397 78.4986Z"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M141.218 83.6361C142.856 83.2021 144.382 82.3901 145.67 81.2981"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M140.462 89.6981C141.806 89.3901 143.08 88.7601 144.116 87.8501"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M81.1723 62.244C79.8003 63.686 77.2943 63.84 75.0543 63.77C73.0663 63.7 71.8343 62.748 70.0283 61.894"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M72.9958 73.5135C70.8818 73.9195 68.3618 73.7655 65.7998 73.4995"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M73.6682 39.2421C71.4562 39.1301 69.2302 39.3681 67.0742 39.9281"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M84.882 39.7881C83.972 39.2141 82.18 39.0041 80.458 39.3121"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M72.4076 74.1167L70.3916 83.2167"
			stroke="#2A2D33"
			strokeWidth="2.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default FryPhone
