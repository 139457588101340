import React from "react"
import { getData } from "./data"
import AutomationCtaA from "../../../components/_V2/AutomationCtaA"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
	request?: Function
}

export default function CtaForm(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	return (
		<AutomationCtaA
			data={data}
			className={props.className}
			withIcon
			request={props.request}
		/>
	)
}
