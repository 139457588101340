import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import mediaContent from "./mediaContent.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Быстрая аналитика",
					tabClassName: mediaContent.tab,
					desktopTitle: <>Следи за продажами в&nbsp;приложении</>,
					desktopContent: (
						<p>
							Проверяй продажи, даже если есть всего несколько секунд. Наличные
							в&nbsp;кассе, выручка, средний чек, количество гостей&nbsp;&mdash;
							показатели за&nbsp;смену отображаются на&nbsp;первом экране
							приложения.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-1-kz.png"
							alt={"приложение для руководителя"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Статистика блюд",
					desktopTitle: "Планируй доход заранее",
					tabClassName: mediaContent.tab,
					desktopContent: (
						<p>
							Узнай какие блюда предпочитают гости или как увеличилась выручка после
							запука акций.
							<br />
							Составь наиболее релевантное меню на&nbsp;основе аналитики. Оценивай
							показатели и&nbsp;тестируй новые решения.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-2-kz.png"
							alt={"приложение для анализа выручки"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Отчёт по кассирам",
					tabClassName: mediaContent.tab,
					desktopTitle: <>Кто продал больше&nbsp;всех?</>,
					desktopContent: (
						<p>
							Изучай показатели с&nbsp;разных сторон: по&nbsp;каждому кассиру,
							отдельному блюду, среднему чеку, количеству гостей или
							комплексно&nbsp;&mdash; по&nbsp;всей работе заведения.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-3-kz.png"
							alt={"анализ продаж по кассирам"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Жылдам аналитика",
					tabClassName: mediaContent.tab,
					desktopTitle: <>Қосымшада сатуды қадағалаңыз</>,
					desktopContent: (
						<p>
							Тіпті егер бірнеше секінд өтсе де, сатуды тексеріңіз. Кассадағы қолма-қол ақша, түсім,
							орташа чек, қонақтар саны - ауысымдағы көрсеткіштер қосымшаның бірінші экранында көрсетіледі.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-1-kz.png"
							alt={"приложение для руководителя"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Тағамдар статистикасы",
					desktopTitle: "Табысты алдына ала жоспарлаңыз",
					tabClassName: mediaContent.tab,
					desktopContent: (
						<p>
							Қонақтар қандай тағамдарды артық көретінін немесе акциялар іске қосылғаннан кейін түсім қалай ұлғайтылғанын біліңіз. <br />
							Аналитика негізінде ең релевантты мәзірді құрыңыз. Көрсектіштерді бағалаңыз және жаңа шешімдерді тестілеңіз.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-2-kz.png"
							alt={"приложение для анализа выручки"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Кассирлер бойынша есеп",
					tabClassName: mediaContent.tab,
					desktopTitle: <>Ең көп сатқан кім?</>,
					desktopContent: (
						<p>
							Көрсеткіштерді жан-жақты зерттеңіз: әр кассир, бөлек тағам, орташа чек, қонақтар саны бойынша
							немесе кешенді түрде - мекеменің бүкіл жұмысы бойынша.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-3-kz.png"
							alt={"анализ продаж по кассирам"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Быстрая аналитика",
					tabClassName: mediaContent.tab,
					desktopTitle: "Смена открыта: смотри, как идут продажи",
					desktopContent: (
						<p>
							Проверяй продажи, даже если есть всего несколько секунд. Наличные
							в&nbsp;кассе, выручка, средний чек, количество гостей&nbsp;&mdash;
							показатели за&nbsp;смену отображаются на&nbsp;первом экране
							приложения.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-1-by.png"
							alt={"приложение для руководителя"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Статистика блюд",
					tabClassName: mediaContent.tab,
					desktopTitle: "Планируй доход заранее",
					desktopContent: (
						<p>
							Узнай какие блюда предпочитают гости или как увеличилась выручка после
							запука акций.
							<br />
							Составь наиболее релевантное меню на&nbsp;основе аналитики. Оценивай
							показатели и&nbsp;тестируй новые решения.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-2-by.png"
							alt={"приложение для анализа выручки"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Отчёт по кассирам",
					tabClassName: mediaContent.tab,
					desktopTitle: "Найди чемпиона продаж",
					desktopContent: (
						<p>
							Изучай показатели с&nbsp;разных сторон: по&nbsp;каждому кассиру,
							отдельному блюду, среднему чеку, количеству гостей или
							комплексно&nbsp;&mdash; по&nbsp;всей работе заведения.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/qrm-reports-3-by.png"
							alt={"анализ продаж по кассирам"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Быстрая аналитика",
				tabClassName: mediaContent.tab,
				desktopTitle: "Смена открыта: смотри, как идут продажи",
				desktopContent: (
					<p>
						Проверяй продажи, даже если есть всего несколько секунд. Наличные
						в&nbsp;кассе, выручка, средний чек, количество гостей&nbsp;&mdash;
						показатели за&nbsp;смену отображаются на&nbsp;первом экране
						приложения.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/qrm-reports-1.png"
						alt={"приложение для руководителя"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 490,
				tabName: "Статистика блюд",
				tabClassName: mediaContent.tab,
				desktopTitle: "Планируй выручку и увеличивай прибыль",
				desktopContent: (
					<p>
						Узнай какие блюда предпочитают гости или как увеличилась выручка после
						запука акций.
						<br />
						Составь наиболее релевантное меню на&nbsp;основе аналитики. Оценивай
						показатели и&nbsp;тестируй новые решения.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/qrm-reports-2.png"
						alt={"приложение для анализа выручки"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 490,
				tabName: "Отчёт по кассирам",
				tabClassName: mediaContent.tab,
				desktopTitle: "Кто продаёт больше?",
				desktopContent: (
					<p>
						Изучай показатели с&nbsp;разных сторон: по&nbsp;каждому кассиру,
						отдельному блюду, среднему чеку, количеству гостей или
						комплексно&nbsp;&mdash; по&nbsp;всей работе заведения.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/qrm-reports-3.png"
						alt={"анализ продаж по кассирам"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}

