import React from 'react'
import ProductPresentationBase from '../../../components/_V2/ProductPresentationBase'
import { getData } from './data'
import styles from './styles.module.scss'
import { useLocalizationContext } from '../../../localization/useLocalizationContext'

export default function ProductPresentation() {
  const localizationContext = useLocalizationContext()

  const data = getData(localizationContext.locale)

  return (
    <ProductPresentationBase
      sectionClass={styles.section}
      containerClass={styles.content}
      data={data}
    />
  )
}
