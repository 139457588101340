import React from "react"
import { contacts, contactsKz, contactsBy } from "../../../pages-data/_V2/common/contacts"
import FryPhone from "./assets/FryPhone"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			icon: <FryPhone />,
			title: "Управляй рестораном или кафе через смартфон",
			description:
				"Приложение доступно на любом тарифе Quick Resto — начни бесплатно с тарифа PRO.",
			phone: contactsKz.phone,
			ctaButtonText: "Заказать звонок",
		}
	}

	if (locale === 'kz-KZ') {
		return {
			icon: <FryPhone />,
			title: "Мейрамхананы немесе кафені смартфон арқылы басқарыңыз",
			description:
				"Қосымша Quick Resto кез келген тарифінде қолжетімді - Pro тарифінен тегін бастаңыз.",
			phone: contactsKz.phone,
			ctaButtonText: "Қоңырауға тапсырыс беру",
		}
	}

	if (locale === 'ru-BY') {
		return {
			icon: <FryPhone />,
			title: "Управляй рестораном или кафе через смартфон",
			description:
				"Приложение доступно на любом тарифе Quick Resto — начни бесплатно с тарифа PRO.",
			phone: contactsBy.phone,
			ctaButtonText: "Заказать звонок",
		}
	}

	return {
		icon: <FryPhone />,
		title: "Управляй рестораном или кафе через смартфон",
		description:
			"Приложение доступно на любом тарифе Quick Resto — начни бесплатно с тарифа Pro.",
		phone: contacts.phone,
		ctaButtonText: "Заказать звонок",
	}
}
